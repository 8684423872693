import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const Scout = () => (
  <Layout className="recruitment">
    <SEO title="Scout" />
    <div className="container pt-2">
      <PageTitle className="mb-2 mb-lg-8" title='Talent scout' />
      <p className="mb-4 text-justify">
        We are always looking for new colleagues to join our Alviso
        team. For all the top clients we are working with we need to
        have a rich base of talent scouts who create a network of
        candidates, information and who link them to appropriate projects.
      </p>
      <h2 className="mb-3">How do you know you want to become Alviso talent scout?</h2>
      <div className="row mb-lg-4 justify-content-xl-between">
        <div className="d-none d-xl-block col-6 col-lg-4 col-xl-2">
          <StaticImage
            src="../assets/images/recruitment/showing.png"
            alt="Graphic"
            layout="fullWidth"
          />
        </div>
        <BlueBox
          className="px-xl-0"
          text={<span>Enjoy <strong>searching</strong> and <strong>analysing</strong></span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>Looking for <strong>amazing colleagues</strong></span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>Love <strong>talking</strong> and <strong>calling</strong> and <strong>emailing</strong></span>}
        />
        <BlueBox
          className="px-xl-0"
          text={<span>You want to build <strong>relationship with top clients</strong></span>}
        />
      </div>
    </div>
    <div className="container pb-4 pb-lg-9">
      <h2 className="mb-3">What it really means</h2>
      <div className="row">
        <div className="col-12 col-md-4 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/scout/step1.png"
              alt="Graphic"
            />
            <h3 className="mb-3 text-center">Step 1 - hunt</h3>
            <p className="text-center">for suitable candidates<br /> for the given project</p>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/scout/step2.png"
              alt="Graphic"
            />
            <h3 className="mb-3 text-center">Step 2 - cooperate with recruiter</h3>
            <p className="text-center">and address<br /> selected candidates</p>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/scout/step3.png"
              alt="Graphic"
            />
            <h3 className="mb-3 text-center">Step 3 - cooperate with candidate</h3>
            <p className="text-center">ideally up<br /> to his dream job</p>
          </div>
        </div>
      </div>
      <p className="text-justify">
        If you are interested and you want to try it, or you are already trying
        elsewhere and you want to try it with us, feel free to get in touch with
        Hanka at her email <a href="mailto:hana.kopecka@alviso.cz">hana.kopecka@alviso.cz </a>
        or phone <a href="tel:00420605475291">+420 605 475 291</a>.
      </p>
    </div>
  </Layout>
);

export default Scout;
